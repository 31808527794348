<template>
<div class="main-wrapper col gap-2">
  <router-link to="/" class="logo"><img src="../assets/img/logo-updated.png" ></router-link>
  <div class="col gap-1">
    <div class="mb-1 row justify-center gap-1">
      <a class="stroke-bullet-button" href="mailto:hello@redbluestories.com">
        <MailSVG></MailSVG>
        <p class="button-label">Contact</p>
      </a>
      <router-link class="stroke-bullet-button" to="/shop">
        <ShopSVG></ShopSVG>
        <p class="button-label">Shop</p>
      </router-link>

    </div>
    <a class="link-block smaller-min" href="https://www.instagram.com/redplusbluestories/" target="_blank">
        <ig-s-v-g id="ig-svg"></ig-s-v-g>
      <div class="col link-content">
        <p class="link-label">Instagram</p>
      </div>
    </a>
    <a class="link-block" href="https://ccpjv9on2mx.typeform.com/to/MkxdQgNK" target="_blank">
      <BouquetSVG class="img"></BouquetSVG>
      <div class="col link-content">
        <p class="link-label">Leave us a review!</p>
        <p class="italic">We’d love to hear from you. Tell us what you liked / didn’t like about our products.</p>
      </div>
    </a>
     <router-link to="/the-bear-chapters-app" class="link-block">
      <img src="../assets/img/the-bear-chapters_app-icon.png">
      <div class="col">
        <div class="tag">companion app</div>
        <p class="link-label">The Bear Chapters</p>
        <p class="italic">The Bear Who Touched The Northern Lights</p>
      </div>
    </router-link>
     <router-link to="/hidden-ar-postcards" class="link-block">
      <img src="../assets/img/TFT_app-icon-rounded.jpg">
      <div class="col">
        <div class="tag">companion app</div>
        <p class="link-label">Tales from the trails</p>
        <p class="italic">Interactive Postcards</p>
      </div>
    </router-link>
    <router-link class="stroke-bullet-button to-home" to="/">
        <p class="button-label centered">website home</p>
    </router-link>
  </div>
</div>
</template>

<script>
import IgSVG from "@/components/svg/igSVG";
import ShopSVG from "@/components/svg/ShopSVG";
import MailSVG from "@/components/svg/MailSVG";
import BouquetSVG from "@/components/svg/BouquetSVG";

export default {
  name: "Links",
  components: {BouquetSVG, MailSVG, ShopSVG, IgSVG}
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables.scss";

a.link-block {
  font-size: 1rem;
  font-weight: 400;
  font-family: Karla, 'sans-serif';
}
.main-wrapper {
  max-width: calc(min(90%, 600px));
  justify-content: center;
  margin: 2rem auto;
}

.logo {
  width: 20vw;
  max-width: 100px;
  margin: 0 auto 0 auto;
}

p {
  padding-bottom: 0;
}

.stroke-bullet-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .8rem;
  padding: .4rem .8rem;
  border: 1px solid $blue;
  border-radius: 14px;
  color: $blue;

  img {
    max-height: 40px;
  }

  &:hover {
    background-color: $blue;
    transition: .2s all ease-in-out;
    color: $teal;
  }
}

.button-label, .link-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}

.button-label.centered {
  width: 100%;
}

.button-label {
  font-size: 0.9rem;
}

.link-label {
  font-size: 1.3rem;
}

.link-block {
  background-color: $cream;
  border-radius: 18px;
  padding: .4rem .8rem;
  color: $blue;
  min-height: 120px;
  gap: 2rem;
  display: grid;
  grid-template-columns: 30% 60%;
  align-items: center;
  transition: .5s all ease-in-out;

  img, .img {
    margin: auto;
    max-height: calc(120px - .8rem);
    border-radius: 14px;
  }

  &.smaller-min {
    min-height: 60px;
  }

  &:hover {
    background-color: $blue;
    transition: .2s all ease-in-out;

    p {
      color: $cream;
    }
  }
}

.justify-center {
  justify-content: center;
}

.tag {
 // align-self: flex-end;
  font-size: 0.7rem;
  margin-bottom: 1rem;
}

.svg-obj {
  pointer-events: none;

  path {
    fill: yellow
  }
}

.to-home {
  padding: 1rem 0;
}

#ig-svg {
  width: 50%;
  height: 50%;
  margin: auto;
}

</style>